.loader {
    border: 10px solid #f3f3f3;
    border-top: 10px solid #013594;
    border-radius: 50%;
    width: 80px;
    height: 80px;
    animation: spin 1s linear infinite;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
.custom-loader {
    border: 5px solid #f3f3f3;
    border-top: 5px solid #013594;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    animation: spin 1s linear infinite;
    position: absolute;
    top: 50%;
    left: calc(50% - 20px);
    transform: translate(-50%, -50%);
  }

.custom-loader-rewards {
    border: 10px solid #f3f3f3;
    border-top: 10px solid #013594;
    border-radius: 50%;
    width: 80px;
    height: 80px;
    animation: spin 1s linear infinite;
    position: absolute;
    top: 85%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
